//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return !this.$store.state.msDynamics.ready
    },
    wrapper() {
      return this.$refs.wrapper
    },
    form() {
      return this.$store.state.msDynamics.formNodes?.[this.type] ?? null
    },
  },
  watch: {
    isLoading() {
      this.moveForm()
    },
  },

  mounted() {
    this.moveForm()
    window.MsCrmMkt?.MsCrmFormLoader?.on('afterFormLoad', this.moveForm)
  },

  beforeDestroy() {
    // Remove the event listener to prevent memory leaks
    const submitButton = document.querySelector(
      '#msdynamics-form-embed-newsletter button[type=submit]'
    )
    if (submitButton) {
      submitButton.removeEventListener('click', this.logEmail)
    }
  },

  methods: {
    moveForm() {
      if (!this.wrapper) return
      if (this.isLoading) return
      if (!this.form) return
      this.wrapper.innerHTML = ''
      const form = this.setFormStyles(this.form)
      this.wrapper.appendChild(form)

      // Add an event listener for the submit button click
      const submitButton = document.querySelector(
        '#msdynamics-form-embed-newsletter button[type=submit]'
      )
      if (submitButton) {
        submitButton.addEventListener('click', this.logEmail)
      }
    },
    setFormStyles(form) {
      // remove style attribute
      form.removeAttribute('style')

      // remove all style attributes except for style tags regarding error messages
      form.querySelectorAll('[style]').forEach((child) => {
        const SKIP_CHILDREN_OF = 'table'
        const EXCEPT_FOR = ['td', 'tbody']

        if (!EXCEPT_FOR.includes(child.nodeName.toLowerCase())) {
          // check if is child of `SKIP_CHILDREN_OF`
          let element = child
          while (element?.parentNode && !element.parentNode.isSameNode(form)) {
            if (element.nodeName.toLowerCase() === SKIP_CHILDREN_OF) {
              // skip this child, to not remove style
              return
            }
            element = element.parentNode
          }
        }

        // remove style
        child.removeAttribute('style')
      })

      // parse text of captcha error messages
      form.querySelectorAll('div[role="alert"]>*').forEach((child) => {
        if (child.innerText?.length > 0 && child.innerText?.includes('\\')) {
          let parsedText
          try {
            // try to parse inner Text to unescape special chars
            parsedText = JSON.parse(
              '"' + child.innerText.replace(/([^\\]|^)\\x/g, '$1\\u00') + '"'
            )
          } catch {}

          if (parsedText) child.innerText = parsedText
        }
      })

      // remove marketing and category fields
      const nameMarketing = process.env.MSD_NEWSLETTER_FIELD_MARKETING
      const nameCategory = process.env.MSD_NEWSLETTER_FIELD_CATEGORY

      form.querySelectorAll('[data-editorblocktype]')?.forEach((input) => {
        const isMatch = !!input.querySelector(
          `[name="${nameMarketing}"], [name="${nameCategory}"]`
        )
        if (isMatch) input.style.display = 'none'
      })

      return form
    },
  },
}
