//
//
//
//
//
//

import { getInjectedFormIds, init } from '~/utils/msDynamics'

export default {
  data() {
    return {
      navGuard: null,
    }
  },
  mounted() {
    // Register nav guard protecting routes from
    // undesired msd includes
    this.navGuard = this.$router.beforeEach((...args) =>
      this.beforeRouteChange(...args)
    )

    // determine forms to be included and store the list in vuex
    // so we can cross-reference this later. Some routes inject no
    // forms. check the excludeFormRoutes variable for a full list
    const forms = getInjectedFormIds(this.$route)
    this.$store.commit('msDynamics/setForms', forms)

    // init all forms
    if (forms.length > 0) {
      init(() => {
        window.MsCrmMkt?.MsCrmFormLoader?.on('afterFormLoad', () => {
          this.$nextTick(() => {
            this.$store.commit('msDynamics/ready')
          })
        })
      }, forms)
    }
  },
  beforeDestroy() {
    if (this.navGuard) {
      this.navGuard()
    }
  },
  methods: {
    beforeRouteChange(to, from, next) {
      const expectedForms = getInjectedFormIds(to).join('|')
      const realForms = this.$store.state.msDynamics.formIds.join('|')
      if (expectedForms !== realForms) {
        const target = this.$router.resolve(to)
        window.location = target.href
        return false
      }
      next()
    },
  },
}
