//
//
//
//
//
//

export default {
  methods: {
    handleClick() {
      const injectedButton = document.querySelector('#ot-sdk-btn')
      if (injectedButton) {
        injectedButton.click()
      }
    },
  },
}
