//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getTranslation from '~/gql/getTranslation'

export default {
  props: {
    location: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        location: null,
        language: this.$i18n.locale,
      },
      translationOptions: {},
    }
  },

  async fetch() {
    const translationPromises = this.$i18n.locales.map((locale) => {
      const translation = this.$graphcms.request(getTranslation, {
        key: locale.code,
        lang: locale.code,
      })
      return translation
    })

    const allTranslations = await Promise.all(translationPromises)
    this.translationOptions = allTranslations.reduce(
      (options, translation, index) => {
        const locale = this.$i18n.locales[index]
        options[locale.code] = translation.translation.text
        return options
      },
      {}
    )
  },

  methods: {
    onInput(field, value) {
      this.formData[field] = value
    },
    onSubmit() {
      this.$router.push({
        path: this.$localeResolver.switchLocalePath(this.formData.language),
      })
      this.$store.commit('locale/setCurrentLocation', this.formData.location)

      this.$store.commit('global/setDialog', {
        dialog: null,
      })
    },
  },
}
