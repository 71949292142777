var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UiForm',{on:{"submit":function($event){return _vm.onSubmit($event)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('UiButton',{attrs:{"type":"submit","size":"small","variant":"secondary","icon-right":"arrow-right"}},[_vm._v(_vm._s(_vm.$translation('button.save')))])]},proxy:true}])},[_c('UiFormField',{attrs:{"id":"language-switch-location","label":_vm.$translation('form.label.location'),"type":"select-country","control":{
      placeholder: 'Worldwide',
      name: 'location',
      autocomplete: 'country-name',
      value: _vm.formData.location ? _vm.formData.location : _vm.location,
      only: [
        'DEU',
        'GBR',
        'SWE',
        'DNK',
        'NLD',
        'GBR',
        'USA',
        'CAN',
        'RUS',
        'AUS',
        'NZL',
        'IND',
        'EGY',
        'SAU',
        'BHR',
        'ARE',
        'ITA' ],
    }},on:{"input":function($event){return _vm.onInput('location', $event)}}}),_vm._v(" "),_c('UiFormField',{attrs:{"id":"language-switch-language","label":_vm.$translation('form.label.language'),"type":"select","control":{
      options: _vm.translationOptions,
      value: _vm.formData.language,
      required: true,
    }},on:{"input":function($event){return _vm.onInput('language', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }